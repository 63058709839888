import React from 'react'
import Helmet from 'react-helmet'

export default function Home() {
  return (
    <>
      <Helmet title="Money.by" defer={false} />
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-base font-semibold text-indigo-600 tracking-wide uppercase">
              Money.by
            </h2>
            <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
              Coming soon
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
